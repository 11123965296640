import React from 'react';
import { Grid, Box, Link } from '@material-ui/core';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import cardImage from '../../images/pexels-towfiqu-barbhuiya-3440682-11137527.jpg'
import { Typography } from '@mui/material';

const NfoGreet = () => {
    return (
        <div>
            <Header />
            <Box
                sx={{            
                    backgroundImage:`url(${cardImage})`,
                    backgroundSize: "100%",
                    height: "800px",
                    backgroundRepeat: "no-repeat",
                    zIndex: -1,
                    overflow: 'hidden',
                    paddingTop: "2%",
                    paddingLeft: "15%",
                    paddingRight: "15%",
                    paddingBottom: "0%",
                    bgcolor: "#6181B0"
                }}
            >
                <Grid
                    container
                    spacing={1}
                    columns={{ xs: 6, md: 12 }}
                    sx={{ 
                        minHeight: '100vh',
                    }}
                >
                    <Grid item xs={12} md={12}>
                        <Typography variant="h4" align="center" fontWeight="bold" 
                            sx={{
                                position: 'relative',
                                color: 'lightslategray', // Text color
                            }}
                        >
                            G2Card
                        </Typography>
                        <Typography align="center" justifyContent="center" 
                            sx={{
                                position: 'relative',
                                color: 'lightslategray',
                                //textShadow: '1px 1px 0px black, -1px -1px 0px black, 1px -1px 0px black, -1px 1px 0px black',
                            }} 
                        >
                            Send e-greeting cards people are excited about with embedded functionality via QR codes! Money, videos, and more all within your e-cards.
                        </Typography>    
                        <Typography align="center">
                            <Link href="https://g2card.co/lander" style={{color: 'lightslategray'}} underline="always"> Learn more.</Link>
                        </Typography>  
                    </Grid>
                    
                </Grid>
            </Box>
            <Footer />
        </div>

    );
}

export default NfoGreet;