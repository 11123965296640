import React, { useEffect } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from 'config/keycloak';
import { Provider } from 'react-redux';
import store from 'redux/store/Store';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import LandingPage from 'components/base/LandingPage';
import NfoGreet from 'components/base/NfoGreet';
import NfoSafe from 'components/base/NfoSafe';
import NfoIQ from 'components/base/NfoIQ';

import { CARD_TASK } from 'constants/Cards';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './redux/store/Store';
import { MuiThemeProvider } from '@material-ui/core';
import THEME from 'themes';
import logoutAfterIdle from 'account/IdleLogout';
import VisibleHomePage from 'components/base/containers/VisibleHomePage';
import HistoricalSchedule from 'components/scheduleCard/HistoricalSchedule';
import NfoSmartAdministration from 'components/nfosmartAdministration/NfoSmartAdministration';
import PrivacyPolicy from 'components/privacy/PrivacyPolicy';

const NfoSmartApp = () => {
  useEffect(() => {
    logoutAfterIdle(30);
  }, []);

  return (
    <MuiThemeProvider theme={THEME}>
      <BrowserRouter>
        <Switch>
          {/*<Route path='/' component={UnderConstructionLandingPage} />*/}
          
          <Route exact path='/' component={LandingPage} />
          <Route path='/G2Card' component={NfoGreet} />
          <Route path='/nfoSafe' component={NfoSafe} />
          <Route path='/nfoIQ' component={NfoIQ} />
          <Route path='/privacy' component={PrivacyPolicy} />
         
          {/*<Route path='/' component={UnderConstructionLandingPage} />*/}
          
          <Route exact path='/' component={LandingPage} />
          <Route path='/G2Card' component={NfoGreet} />
          <Route path='/nfoSafe' component={NfoSafe} />
          <Route path='/nfoIQ' component={NfoIQ} />
          <Route path='/privacy' component={PrivacyPolicy} />
         
          <ReactKeycloakProvider
            authClient={keycloak}
            initOptions={{ onLoad: 'login-required' }}
            LoadingComponent={<div>Please wait...</div>}
          >
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <Route path='/home' component={VisibleHomePage} />
                <Route path='/history' component={HistoricalSchedule} />
                <Route path='/administration' component={NfoSmartAdministration} />
                {CARD_TASK.ROUTES.map((route, index) => (
                  <Route path={route} component={CARD_TASK.COMPONENTS[index]} />
                ))}
              </PersistGate>
            </Provider>
          </ReactKeycloakProvider>
        </Switch>
      </BrowserRouter>
    </MuiThemeProvider>
  );
};

export default NfoSmartApp;
