import React from 'react';
import { Container, Typography, Link, Box } from '@mui/material';

const Footer = () => (
  <Box
    component="footer"
    sx={{
      py: 2,
      backgroundColor: '#6181B0',
      textAlign: 'center',
    }}
  >
    <Container maxWidth="lg">
      <Typography variant="body2" color="lightgray">
        © {new Date().getFullYear()} NfoSmart. All rights reserved.
      </Typography>
      <Typography variant="body2" color="lightgray">
        <Link href="/privacy" underline="hover" color="lightgray">
          Privacy Policy
        </Link>
      </Typography>
    </Container>
  </Box>
);

export default Footer;