import React from 'react';
import { Grid, Box, Link } from '@material-ui/core';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import AppleIcon from '@mui/icons-material/Apple';
import ShopIcon from '@mui/icons-material/Shop';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@mui/material';
import hackerImage from '../../images/pexels-john-tekeridis-21837-19285776.jpg'


const handleAppleClick = () => {
    // Handle Apple App Store click
    window.open('https://www.apple.com/app-store/', '_blank');
  };

  const handleGoogleClick = () => {
    // Handle Google Play Store click
    window.open('https://play.google.com/store', '_blank');
  };

const NfoIQ = () => {
    return (
        <div>
            <Header />
            <Box 
                sx={{            
                    backgroundImage:`url(${hackerImage})`,
                    backgroundSize: "100%",
                    height: "800px",
                    backgroundRepeat: "no-repeat",
                    zIndex: -1,
                    paddingTop: "2%",
                    paddingLeft: "15%",
                    paddingRight: "15%",
                    paddingBottom: "0%",
                    bgcolor: "#000000"
                }}
            >
                <Grid
                    container
                    spacing={1}
                    columns={{ xs: 6, md: 12 }}
                    sx={{ 
                        minHeight: '100vh',
                    }}
                >
                    <Grid item xs={12} md={12}>
                        <Typography variant="h4" color="common.white" align="center" fontWeight="bold">NfoIQ</Typography>
                        <br/>
                        <Typography color="common.white" align="center" justifyContent="center">
                            The internet is a dangerous place. NfoIQ provides you with peace of mind. 
                        </Typography>    
                        <Typography align="center">
                            <Link href="https://nfoiq.com/home-1" style={{color: 'lightslategray'}} underline="always"> Learn more.</Link>
                        </Typography>                
                    </Grid>
                    <Grid item xs={12} md={6} align="center">
                        <Tooltip title="Download on the App Store">
                            <IconButton onClick={handleAppleClick} style={{ color: 'white' }}>
                                <AppleIcon style={{ fontSize: 75 }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} md={6} align="center">
                        <Tooltip title="Get it on Google Play">
                            <IconButton onClick={handleGoogleClick} style={{ color: 'white' }}>
                                <ShopIcon style={{ fontSize: 75 }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Box>
            <Footer />
        </div>
    );
}

export default NfoIQ;