import React from 'react';
import 'App.css'
import { Typography, Grid, Box, Card, CardContent } from '@mui/material';

import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';

import LandingImage from '../../images/nfosmart_landing.webp'
import AboutImage from '../../images/about.webp'
import TeamImage from '../../images/team.webp'
import ServicesImage from '../../images/services.webp'

const sections = [
    {
        title: 'About',
        content: 'NFOSAFE provides NFO IQ with verification data. We utilize our patented technology to verify and score the risk level of URLs to make QR Codes safer. We are continuously gaining data and insight to protect you from scams and risky websites.',
        image: AboutImage
    },
    {
        title: 'Our Services',
        content: 'We provide verification so that when unverified QR Codes are detected, users are alerted to check for potentially  unexpected scams. We provide ongoing monitoring and alerts. We help keep users from being scammed.',
        image: TeamImage
    },
    {
        title: 'Our Team',
        content: 'Our team of software engineers and developers has years of experience in the industry. We stay up to date with the latest trends and technologies to ensure our clients receive the best possible protection.',
        image: ServicesImage
    }
];
  
const SectionCard = ({ title, content }) => (
    <Card sx={{ mb: 2 }}>
        <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
            {title}
        </Typography>
        <Typography variant="body2">
            {content}
        </Typography>
        </CardContent>
    </Card>
);



const NfoSafe = () => {
    return (
        <div>
            <Header />
            <Grid container>
                <Grid item sx={{display: { xs: 'none', sm: 'contents' }}}>
                    <Box sx={{
                        backgroundImage:`url(${LandingImage})`,
                        backgroundSize: "100%",
                        height: "400px",
                        width: "100%",
                        backgroundRepeat: "no-repeat",
                        
                        overflow: 'hidden',
                        paddingTop: "15%",
                        paddingLeft: "15%",
                        paddingRight: "15%",
                        paddingBottom: "5%"
                    }}>
                        <Typography variant="h4" color="common.white" sx={{textAlign: "center", width: "100%"}}>NFOSMART</Typography>
                    </Box>  
                </Grid>
                <br/>
                <Grid item>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6, md: 12 }}>
                        {sections.map((section, index) => (
                            <Grid item xs={6} md={4} key={index} className="info-section-card">
                                <Box
                                    sx={{
                                        position: 'relative',
                                        zIndex: -1,
                                        width: '100%',
                                        paddingTop: '56.25%', // 16:9 aspect ratio (modify as needed)
                                        overflow: 'hidden',
                                    }}
                                >
                                <img
                                    src={section.image}
                                    alt={section.title}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover', // or 'contain' depending on your preference
                                    }}
                                />
                                </Box>
                                <SectionCard title={section.title} content={section.content} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        </div>
    );
}

export default NfoSafe;