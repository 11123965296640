import React from 'react';
import {
  AppBar,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ProfileIcon from 'components/base/ProfileIcon';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, useHistory } from 'react-router-dom';
import { Fragment } from 'react';
import VisibleHamburgerMenu from 'components/base/containers/VisibleHamburgerMenu';

const useStyles = makeStyles((theme) => ({
  logo: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 700,
    color: 'black',
    [theme.breakpoints.only('xs')]: {
      fontSize: '2rem',
      color: 'white',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  appbar: {
    boxShadow: 'none',
    paddingTop: '50px',
    [theme.breakpoints.only('xs')]: {
      backgroundColor: 'black',
      paddingTop: '20px',
      position: 'fixed',
    },
  },
  navbarTabs: {
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  mobileMenu: {
    display: 'none',
    color: 'white',
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
    },
  },
  offset: {
    [theme.breakpoints.only('xs')]: {
      minHeight: '100px',
    },
  },
}));

const NavigationBar = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (route) => {
    setAnchorEl(null);
    if (route) {
      history.push(route);
    }
  };

  return (
    <Fragment>
      <AppBar position='static' color='transparent' className={classes.appbar}>
        <Toolbar>
          <Grid container item justify='flex-end' direction='column' alignContent='center'>
            <Grid container item lg={8} xs={12}>
              <Grid container item xl={3} lg={4} xs={6} justify='center' alignContent='center'>
                <Typography
                  id='welcome-header'
                  variant='h2'
                  color='textPrimary'
                  classes={{ h2: classes.logo }}
                  align='center'
                >
                  <Link id='logo' to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                    NFOSMART
                  </Link>
                </Typography>
              </Grid>
              <Grid container className={classes.navbarTabs} xl={9} lg={8}>
                <Grid container xl={3} lg={5} justify='center' alignItems='center' spacing={3}>
                  <Grid item>
                    <Link id='about-link' to='/about' className={classes.link}>
                      About
                    </Link>
                  </Grid>
                  <Grid item>
                    <a id='contact-link' href='mailto:stephonlee@nfosmart.com' className={classes.link}>
                      Contact Us
                    </a>
                  </Grid>
                </Grid>
                <Grid container xl={9} lg={7} justify='flex-end' alignItems='center'>
                  <Grid item>
                    <Link id='login-link' to='/home' className={classes.link}>
                      Log In | Sign Up
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={classes.mobileMenu} justify='flex-end' xs={6}>
                <IconButton
                  aria-label='account of current user'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  onClick={handleMenu}
                  color='inherit'
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id='menu-appbar'
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => handleClose('/')}>Home</MenuItem>
                  <MenuItem onClick={() => handleClose('/about')}>About</MenuItem>
                  <MenuItem onClick={handleClose}>
                    <a href='mailto:stephonlee@nfosmart.com' className={classes.link}>
                      Contact Us
                    </a>
                  </MenuItem>
                  <MenuItem onClick={() => handleClose('/home')}>Log In | Sign Up</MenuItem>
                </Menu>
              </Grid>
            </Grid>

            <Grid xs style={{ height: '20px' }}>
              <hr style={{ width: '100%' }} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.offset} />
    </Fragment>
  );
};

export const MainHeader = () => {
  return (
    <AppBar position='static' color='primary'>
      <Toolbar>
        <Grid container justify='space-between' wrap='wrap'>
          <Grid container item xs={4}>
            <VisibleHamburgerMenu />
            <Typography id='main-header' variant='h4' style={{ lineHeight: 'initial' }}>
              <b>NFOSMART</b>
            </Typography>
          </Grid>
          <Grid container xs={4} justify='center' alignItems='center'></Grid>
          <Grid container xs={4} justify='flex-end'>
            <ProfileIcon />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default NavigationBar;
