import React from 'react';
import 'App.css';
import { Typography, Grid, Box } from '@mui/material';

import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import AboutUs from 'components/aboutUs/AboutUs';
import backgroundImage from '../../images/pexels-jopwell-2422294.jpg'

const LandingPage = () => {
  return (
    <div>
      <Header/>
      <Grid container>
        <Grid item sx={{display: { xs: 'none', sm: 'block' }}}>
          <Box sx={{
            backgroundImage:`url(${backgroundImage})`,
            backgroundSize: "100%",
            height: "400px",
            backgroundRepeat: "no-repeat",
            overflow: 'hidden',
            paddingTop: "15%",
            paddingLeft: "15%",
            paddingRight: "15%",
            paddingBottom: "5%"
          }}>
            <Typography variant="h4" color="common.white">NfoSafe is a parent company creating new tools to make achieving your goals easier. 
              Our holistic approach leads us to consider problems from every angle, all to find areas where no solutions exist and to BE the solution.</Typography>
          </Box>  
        </Grid>
        <br/>
        <Grid item>
          <AboutUs/>
        </Grid>
      </Grid>
      <Footer/>
    </div>
    
  );
};

export default LandingPage;
