import React from 'react';
import aboutUsImage from '../../images/pexels-photo-3862615.jpg';
import ourValuesImage from '../../images/pexels-fauxels-3183150.jpg'
import ourMissionImage from '../../images/pexels-mikhail-nilov-7682340.jpg'
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';

const sections = [
  {
    title: 'About Us',
    content: 'We are a forward-thinking company dedicated to innovation and excellence in technology. Our team is passionate about delivering top-notch solutions to our clients.',
    image: aboutUsImage
  },
  {
    title: 'Our Values',
    content: 'Integrity, creativity, and customer satisfaction are at the core of everything we do. We believe in fostering a culture of respect and collaboration to achieve the best results.',
    image: ourValuesImage
  },
  {
    title: 'Our Mission',
    content: 'Our mission is to revolutionize the client experience through innovative technology and exceptional service, empowering our clients to achieve their goals and drive success.',
    image: ourMissionImage
  }
];

const SectionCard = ({ title, content }) => (
  <Card sx={{ mb: 2 }}>
    <CardContent>
      <Typography variant="h5" component="div" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body2">
        {content}
      </Typography>
    </CardContent>
  </Card>
);


const InfoSections = () => (
  <div>
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6, md: 12 }} alignItems="center"
      justifyContent="center">
        {sections.map((section, index) => (
            <Grid item xs={6} md={4} key={index} className="info-section-card">
                <Box
                  sx={{
                    position: 'relative',
                    width: '100%',
                    zIndex: -1,
                    paddingTop: '56.25%',
                    overflow: 'hidden',
                  }}
                >
                  <img
                    src={section.image}
                    alt={section.title}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover', // or 'contain' depending on your preference
                    }}
                  />
                </Box>
                <SectionCard title={section.title} content={section.content} />
            </Grid>
        ))}
    </Grid>
  </div>
);


export default InfoSections;