import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { Contacts } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';

const LoginFooter = () => {
  return (
    <Grid
      container
      alignItems='center'
      style={{ position: 'absolute' }}
    >
      <Grid container justify='center' xs={2}>
        <Grid
          container
          item
          justify='center'
          spacing={1}
          style={{ textDecoration: 'none', color: 'black' }}
          component={Link}
          to='/about'
          xs={8}
        >
          <Grid item>
            <Contacts id='contact-icon' />
          </Grid>
          <Grid item>
            <Typography id='about-ref' variant='caption' align='center'>
              What is NFOSMART?
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const MainFooter = () => {
  return (
    <Tooltip
      id='contact-tooltip'
      title='Contact Us'
      placement='top'
      arrow
      style={{ position: 'absolute', bottom: 0, right: 0, padding: 10 }}
    >
      <IconButton href="mailto:stephonlee@nfosmart.com">
        <ContactSupportIcon />
      </IconButton>
    </Tooltip>
  );
};

export default LoginFooter;
