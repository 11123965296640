import React, { useState } from 'react';
import './header.css';
import { CloseOutlined, MenuOutlined } from '@material-ui/icons';

function Header() {
    const [showMenu, setShowMenu] = useState(false);
    const navLinks = [
        {
            title: "NfoIQ",
            path: '/nfoIQ'
        },
        {
            title: "NfoSafe",
            path: '/nfoSafe'
        },
        {
            title: "G2Card",
            path: '/G2Card'
        }
    ];

    const handleShowMenu = () => {
        setShowMenu(!showMenu);
    }
    const closeShowMenu = () => {
        setShowMenu(false);
    }
    return (
        <div className="header-container">
            <span ><a href='/' className="site-logo">NfoSmart</a></span>
            <div className="menu-item-container">
                {showMenu ? (<CloseOutlined className="menu-icon" onClick={closeShowMenu} />)
                    : (<MenuOutlined className="menu-icon" onClick={handleShowMenu} />)}
            </div>
            <div className="nav-item-container">
                {navLinks?.map((i) => (
                    <div className="nav-item"><a href={i.path} className="nav-text">{i.title}</a></div>
                ))}
            </div>
            <div className={showMenu ? "side-menu active" : "side-menu"}>
                {navLinks?.map((i) => (
                    <div className="nav-item"><a href={i.path} className="nav-text">{i.title}</a></div>
                ))}
            </div>
        </div>
    );
}

export default Header;